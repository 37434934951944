import React, { useState, useEffect } from "react";
import moment from "moment";
import { GameSection } from "./components/layout/GameSection";
import { StatusSection } from "./components/layout/StatusSection";
import { NewGameWindow } from "./components/NewGameWindow";
import { getUniqueSudoku } from "./solver/UniqueSudoku";
import { useSudokuContext } from "./context/SudokuContext";

/**
 * Game is the main React component.
 */
export const Game: React.FC<{}> = () => {
  /**
   * All the variables for holding state:
   * gameArray: Holds the current state of the game.
   * initArray: Holds the initial state of the game.
   * solvedArray: Holds the solved position of the game.
   * difficulty: Difficulty level - 'Easy', 'Medium' or 'Hard'
   * numberSelected: The Number selected in the Status section.
   * timeGameStarted: Time the current game was started.
   * mistakesMode: Is Mistakes allowed or not?
   * fastMode: Is Fast Mode enabled?
   * cellSelected: If a game cell is selected by the user, holds the index.
   * history: history of the current game, for 'Undo' purposes.
   * overlay: Is the 'Game Solved' overlay enabled?
   * won: Is the game 'won'?
   */
  let {
    numberSelected,
    setNumberSelected,
    gameArray,
    setGameArray,
    difficulty,
    setDifficulty,
    setTimeGameStarted,
    fastMode,
    setFastMode,
    notesMode,
    setNotesMode,
    cellSelected,
    setCellSelected,
    initArray,
    setInitArray,
    setWon,
    showNewGame,
    setShowNewGame
  } = useSudokuContext();
  let [mistakesMode, setMistakesMode] = useState<boolean>(false);
  let [history, setHistory] = useState<string[][]>([]);
  let [solvedArray, setSolvedArray] = useState<string[]>([]);
  let [overlay, setOverlay] = useState<boolean>(false);

  let [showRestart, setShowRestart] = useState(false);
  let nextDifficulty: string = "";

  /**
   * Creates a new game and initializes the state variables.
   */
  function _createNewGame(e?: React.ChangeEvent<HTMLSelectElement>) {
    if (String(localStorage.getItem("difficulty"))) {
      setDifficulty(JSON.parse(String(localStorage.getItem("difficulty"))));
    }

    let [temporaryInitArray, temporarySolvedArray] = getUniqueSudoku(
      difficulty,
      e
    );

    let localGameArray = String(localStorage.getItem("gameArray"));
    let localInitArray = String(localStorage.getItem("initArray"));
    let localSolvedArray = String(localStorage.getItem("solvedArray"));
    // let localDifficulty = String(localStorage.getItem("difficulty"));

    if (
      localStorage.getItem("gameArray") &&
      localStorage.getItem("initArray") &&
      localStorage.getItem("solvedArray") &&
      localStorage.getItem("difficulty")
    ) {
      setGameArray(JSON.parse(localGameArray));
      setInitArray(JSON.parse(localInitArray));
      setSolvedArray(JSON.parse(localSolvedArray));
      // setDifficulty(JSON.parse(localDifficulty));
    } else {
      clearGameLocal();
      setGameArray(temporaryInitArray);
      setInitArray(temporaryInitArray);
      setSolvedArray(temporarySolvedArray);
      setDifficulty(difficulty);
    }

    setNumberSelected("0");
    setTimeGameStarted(moment());
    setCellSelected(-1);
    setHistory([]);
    setWon(false);
    setShowNewGame(false);
  }

  /**
   * Checks if the game is solved.
   */
  function _isSolved(index: number, value: string) {
    if (
      gameArray.every((cell: string, cellIndex: number) => {
        if (cellIndex === index) return value === solvedArray[cellIndex];
        else return cell === solvedArray[cellIndex];
      })
    ) {
      return true;
    }
    return false;
  }

  /**
   * Fills the cell with the given 'value'
   * Used to Fill / Erase as required.
   */
  function _fillCell(index: number, value: string) {
    if (initArray[index] === "0") {
      // Direct copy results in interesting set of problems, investigate more!
      let tempArray = gameArray.slice();
      let tempHistory = history.slice();

      // Can't use tempArray here, due to Side effect below!!
      tempHistory.push(gameArray.slice());
      setHistory(tempHistory);

      tempArray[index] = value;
      setGameArray(tempArray);

      if (_isSolved(index, value)) {
        setOverlay(true);
        setWon(true);
      }
    }
  }

  /**
   * A 'user fill' will be passed on to the
   * _fillCell function above.
   */
  function _userFillCell(index: number, value: string) {
    if (mistakesMode) {
      if (value === solvedArray[index] && !Array.isArray(JSON.parse(value))) {
        _fillCell(index, value);
      } else {
        // TODO: Flash - Mistakes not allowed in Mistakes Mode
      }
    } else {
      _fillCell(index, value);
    }
  }

  /**
   * On Click of 'New Game' link,
   * create a new game.
   */
  function onClickNewGame() {
    // clearGameLocal();
    // _createNewGame();
    setShowRestart(true);
    setShowNewGame(true);
  }

  /**
   * On Click of a Game cell.
   */
  function onClickCell(indexOfArray: number) {
    if (fastMode && numberSelected !== "0") {
      _userFillCell(indexOfArray, numberSelected);
    }
    setCellSelected(indexOfArray);
  }

  /**
   * On Change Difficulty,
   * 1. Update 'Difficulty' level
   * 2. Create New Game
   */
  function onChangeDifficulty(e: React.ChangeEvent<HTMLSelectElement>) {
    setShowRestart(false);
    setDifficulty(e.target.value);
    setShowNewGame(true);
  }

  /**
   * On Click of Number in Status section,
   * either fill cell or set the number.
   */
  function onClickNumber(number: string) {
    if (fastMode) {
      setNumberSelected(number);
    } else if (cellSelected !== -1) {
      if (!notesMode) {
        _userFillCell(cellSelected, number);
      } else {
        let cellNotes = Array.isArray(JSON.parse(gameArray[cellSelected]))
          ? JSON.parse(gameArray[cellSelected])
          : [];

        _userFillCell(cellSelected, JSON.stringify([...cellNotes, number]));
      }
    }
  }

  /**
   * On Click Undo,
   * try to Undo the latest change.
   */
  function onClickUndo() {
    if (history.length) {
      let tempHistory = history.slice();
      let tempArray = tempHistory.pop();
      setHistory(tempHistory);
      if (tempArray !== undefined) setGameArray(tempArray);
    }
  }

  /**
   * On Click Erase,
   * try to delete the cell.
   */
  function onClickErase() {
    if (cellSelected !== -1 && gameArray[cellSelected] !== "0") {
      _fillCell(cellSelected, "0");
    }
  }

  /**
   * On Click Hint,
   * fill the selected cell if its empty or wrong number is filled.
   */
  function onClickHint() {
    if (cellSelected !== -1) {
      _fillCell(cellSelected, solvedArray[cellSelected]);
    }
  }

  /**
   * Toggle Mistakes Mode
   */
  function onClickMistakesMode() {
    setMistakesMode(!mistakesMode);
  }

  /**
   * Toggle Fast Mode
   */
  function onClickFastMode() {
    if (fastMode) {
      setNumberSelected("0");
    }
    setCellSelected(-1);
    setFastMode(!fastMode);
  }

  function onClickNotesMode() {
    setNotesMode(!notesMode);
  }

  function onKeyDown(key: string) {
    if (key === "Delete" || key === "Backspace") {
      onClickErase();
    }

    if (cellSelected !== -1 && key >= "0" && key <= "9") {
      if (!notesMode) {
        _userFillCell(cellSelected, key);
      } else {
        let cellNotes = Array.isArray(JSON.parse(gameArray[cellSelected]))
          ? JSON.parse(gameArray[cellSelected])
          : [];

        _userFillCell(cellSelected, JSON.stringify([...cellNotes, key]));
      }
    }
  }

  /**
   * Close the overlay on Click.
   */
  function onClickOverlay() {
    clearGameLocal();

    setOverlay(false);
    _createNewGame();
  }

  function onClickYes() {
    setDifficulty(nextDifficulty);
    saveGameLocal();

    clearGameLocal();
    _createNewGame();
  }

  function onClickNo() {
    setDifficulty(JSON.parse(localStorage.getItem("difficulty") ?? "Easy"));
    setShowNewGame(false);
    setShowRestart(false);
  }

  function restartCurrent() {
    localStorage.setItem("gameArray", JSON.stringify(initArray));
    localStorage.setItem("initArray", JSON.stringify(initArray));
    localStorage.setItem("solvedArray", JSON.stringify(solvedArray));
    // localStorage.setItem("difficulty", JSON.stringify(difficulty));

    _createNewGame();
  }

  function saveGameLocal() {
    localStorage.setItem("gameArray", JSON.stringify(gameArray));
    localStorage.setItem("initArray", JSON.stringify(initArray));
    localStorage.setItem("solvedArray", JSON.stringify(solvedArray));
    localStorage.setItem("difficulty", JSON.stringify(difficulty));
  }

  function clearGameLocal() {
    localStorage.removeItem("gameArray");
    localStorage.removeItem("initArray");
    localStorage.removeItem("solvedArray");
    // localStorage.removeItem("difficulty");
  }

  useEffect(() => {
    if (gameArray.length) {
      saveGameLocal();
    }
  }, [gameArray]);

  /**
   * On load, create a New Game.
   */
  useEffect(() => {
    _createNewGame();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NewGameWindow
        showMode={showNewGame}
        showRestart={showRestart}
        onClickRestart={restartCurrent}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
      <div className={overlay ? "game_container blur" : "game_container"}>
        <div
          className="innercontainer"
          tabIndex={0}
          onKeyDown={(ev) => onKeyDown(ev.key)}>
          <GameSection
            onClick={(indexOfArray: number) => onClickCell(indexOfArray)}
          />
          <StatusSection
            onClickNewGame={onClickNewGame}
            onClickNumber={(number: string) => onClickNumber(number)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              onChangeDifficulty(e)
            }
            onClickUndo={onClickUndo}
            onClickErase={onClickErase}
            onClickHint={onClickHint}
            onClickMistakesMode={onClickMistakesMode}
            // onClickFastMode={onClickFastMode}
            onClickNotesMode={onClickNotesMode}
          />
        </div>
      </div>
      <div
        className={overlay ? "overlay overlay--visible" : "overlay"}
        onClick={onClickOverlay}>
        <h2 className="overlay__text">
          You <span className="overlay__textspan1">solved</span>{" "}
          <span className="overlay__textspan2">it!</span>
        </h2>
      </div>
    </>
  );
};
