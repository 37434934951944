import React from "react";
import { getTranslation } from "../getTranslation";

type NewGameProps = {
  onClickNewGame: () => void;
};

export const NewGame = (props: NewGameProps) => {
  return (
    <div className="new-game" onClick={props.onClickNewGame}>
      {getTranslation("new-game")}
    </div>
  );
};
