import React from "react";
import { useSudokuContext } from "../context/SudokuContext";
import { getTranslation } from "../getTranslation";

type DifficultyProps = {
  difficulty?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

/**
 * React component for the Difficulty Selector.
 */
export const Difficulty = (props: DifficultyProps) => {
  let { difficulty } = useSudokuContext();

  return (
    <div className="status__difficulty">
      <select
        name="status__difficulty-select"
        className="status__difficulty-select"
        value={difficulty}
        onChange={props.onChange}>
        <option value="Easy">{getTranslation("easy")}</option>
        <option value="Medium">{getTranslation("medium")}</option>
        <option value="Hard">{getTranslation("hard")}</option>
      </select>
    </div>
  );
};
