import importedLangData from "./translate.json";

let langData: { [index: string]: any } = importedLangData;

function getTranslation(key: string) {
  let lang = document.querySelector("#root")?.getAttribute("lang") ?? "en";
  return langData[key][lang];
}

export { getTranslation };
