import React from "react";
import { NewGame } from "../NewGame";
import { Difficulty } from "../Difficulty";
import { Timer } from "../Timer";
import { Numbers } from "../Numbers";
import { Action } from "../Action";
import { Mode } from "../Mode";

type StatusSectionProps = {
  onClickNewGame: () => void;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  difficulty?: string;
  onClickNumber: (number: string) => void;
  onClickUndo: () => void;
  onClickErase: () => void;
  onClickHint: () => void;
  onClickMistakesMode: () => void;
  // onClickFastMode: () => void,
  onClickNotesMode: () => void;
};

/**
 * React component for the Status Section.
 */
export const StatusSection = (props: StatusSectionProps) => {
  return (
    <section className="status">
      <section className="status_top">
        <NewGame onClickNewGame={props.onClickNewGame} />
        <Difficulty onChange={props.onChange} difficulty={props.difficulty} />
      </section>
      <Timer />
      <Numbers onClickNumber={(number) => props.onClickNumber(number)} />
      <div className="status__actions">
        <Action action="undo" onClickAction={props.onClickUndo} />
        <Action action="erase" onClickAction={props.onClickErase} />
        <Action action="hint" onClickAction={props.onClickHint} />
        <Mode mode="mistakes" onClickMode={props.onClickMistakesMode} />
        {/* <Mode mode='fast' onClickMode={props.onClickFastMode} /> */}
        <Mode mode="notes" onClickMode={props.onClickNotesMode} />
      </div>
    </section>
  );
};
