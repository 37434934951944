import React from "react";
import { getTranslation } from "../getTranslation";

type NewGameWindowProps = {
  showMode: boolean;
  showRestart: boolean;
  onClickRestart: () => void;
  onClickYes: () => void;
  onClickNo: () => void;
};

export const NewGameWindow = (props: NewGameWindowProps) => {
  return (
    <div
      className={`new-game-window ${
        props.showMode ? "new-game-window_active" : "new-game-window_disabled"
      }`}>
      <div className="new-game-window_text">
        {getTranslation("new-game-window_confirm")}
      </div>
      {props.showRestart && (
        <div
          className="new-game-window_button new-game-window_big-button"
          onClick={props.onClickRestart}>
          {getTranslation("new-game-window_restart")}
        </div>
      )}
      <div className="new-game-window_bottom-buttons">
        <div
          className="new-game-window_button new-game-window_small-button"
          onClick={props.onClickYes}>
          {getTranslation("new-game-window_yes")}
        </div>
        <div
          className="new-game-window_button new-game-window_small-button"
          onClick={props.onClickNo}>
          {getTranslation("new-game-window_no")}
        </div>
      </div>
    </div>
  );
};
